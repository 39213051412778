@import "../../../../../variables";

.messageContainer {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  padding: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.message {
  color: $dark-gray-color;
  text-align: center;

  h4 {
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 0;
  }

  p {
    color: $medium-gray-on-white-bg-color;
  }
}

.icon {
  color: #85e892;
  font-size: 150px;
}

.failureIcon {
  color: #f65e5e;
}

.btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  background-color: $accent-color;
  border: 2px solid $accent-color;
  color: $off-white-color;
  font-size: 14px;
  padding: 24px;
  transition: 0.3s all;
  margin-top: 50px;

  &:hover {
    color: $dark-gray-color;
    background-color: $off-white-color;
  }

  &:focus {
    outline: none;
  }
}