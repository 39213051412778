@import '../../../../variables';
@import '../../../../animations';

.hamburger {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 10px;
  z-index: 100;
  padding: 24px;
}

.hamburgerInner {
  width: 24px;
  left: 12px;
  top: 20px;
}

.hamburgerInner::after {
  top: 8px;
  left: 5px;
  width: 19px;
  display: block;
  content: "";
}

.hamburgerInner, .hamburgerInner:after {
  position: absolute;
  height: 2px;
  background-color: $off-white-color;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.isActive {
  .hamburgerInner {
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }

  .hamburgerInner::after {
    transform: translate3d(-2.5px, -8px, 0) rotate(-270deg) scaleX(1.25);
    transition-delay: 0.075s;
  }
}




