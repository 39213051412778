@import '../../../../../variables';

.ctaContainer {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);
  -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);
  transition: all 0.2s;
  width: 100%;
  cursor: pointer;

  @media screen and (max-width: $md-screen) {
    flex-direction: column !important;
    margin-right: -10vw;
    margin-left: -10vw;
    width: unset;
  }
}

.ctaContainer:hover {
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.55);
  -webkit-box-shadow: 0 0 40px 0 rgba(0,0,0,0.55);
  -moz-box-shadow: 0 0 40px 0 rgba(0,0,0,0.55);
}

.innerCtaContainer {
  display: flex;
  padding: 30px;
  align-items: center;
  margin: 20px auto;
  max-width: 800px;

  @media screen and (max-width: $md-screen) {
    flex-direction: column !important;
  }
}

.textWrapper {
  width: 70%;

  div {
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px ;
  }
}

.divider {
  height: 1px;
  border: none;
  width: 70%;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.btn {
  border: 1px solid $soft-gray-color;
  background-color: white;
  line-height: 100%;
  font-weight: 600;
  padding: 20px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  height: fit-content;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);
  -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,0.45);

  @media screen and (max-width: $md-screen){
    margin-top: 20px;
  }
}

.ctaContainer:hover .btn {
  letter-spacing: 1px;
}




