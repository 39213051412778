@import '../../../../variables.scss';

.container {
  margin-top: calc(5px - 3.125vw);
}

.tag {
  cursor: pointer;
  display: inline-block;

  &:after {
    content: ',\00a0';
  }

  &:last-child:after {
    content: none;
  }
}

.tag:hover {
  color: $accent-color;
}

.caseStyleTag{
  color: $black-color;
  text-transform: initial;
  @media (min-width: 320px) {
    font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
  }
  @media (min-width: 1440px) {
    font-size: 22px;
  }
}

.caseStyleTag:hover {
  &:after {
    color: initial;
  }
}

.articleStyleTag {
  font-size: smaller;
  color: $soft-gray-color;
}

.articleStyleTag:hover {
  &:after {
    color: $soft-gray-color;
  }
}