@import '../../../variables';
@import '../../../animations';

body .section {
  text-align: left;
  padding: $large-indentation 10vw $xs-indentation 10vw
}

.title {
  margin-top: $xs-indentation;
  margin-bottom: $medium-indentation;
  color: $black-color;
  @media screen and (min-width: $lg-screen) {
    width: 80%;
  }
}

.link {
  display: inline-block;
  color: $black-color;
  cursor: pointer;
  background-color: transparent;
}

.addressText {
  margin-top: $sm-indentation;
}

.container {
  color: $dark-gray-color;
  margin-bottom: $large-indentation;

  .contacts {
    flex: 1;
    width: 100%;
    min-width: 40vw;
  }

  .contacts > p, .contacts > div > p {
    margin: 0;
  }

  .address {
    flex: 1;
  }

  @media screen and (min-width: $lg-screen) {
    display: flex;
    justify-content: space-between;

    .addressText {
      margin: 0;
    }
  }
}

body .grey {
  color: $soft-gray-color;
  min-height: 48px;
  line-height: 48px;
  vertical-align: middle;
}

.policyContainer {
  //flex: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $lg-screen) {
  .policyLink:after {
    content: '';
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 2px;
    vertical-align: middle;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $soft-gray-color;
  }

  .policyContainer {
    flex-direction: row;
  }
}

@media screen and (min-width: $xl-screen) {
  .footerContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.copyright {
  flex: 1;
}

.wordsWrapper {
  position: relative;
  font-size: inherit;
  color: $black-color;
  background-color: transparent;

  b {
    position: relative;
    white-space: nowrap;
    font-weight: 500;
    line-height: 100%;
    background-color: transparent;
    z-index: 100;
    transition: .2s all;

    &:before{
      content: '';
      -webkit-animation: dotToLine 0.4s;
      -moz-animation: dotToLine 0.4s;
      animation: dotToLine 0.4s;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $accent-color;
      width: 100%;
      min-height: 3px;
    }
  }
}

.wordsWrapper:hover {

  b {
    &:before{
      transform: translateX(1rem);
      -webkit-animation: lineToDot 0.4s;
      -moz-animation: lineToDot 0.4s;
      animation: lineToDot 0.4s;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      bottom: 20%;
    }
  }
}
