@import '../../../../variables';
@import '../../../../animations';

.spreadCircle {
  border: 20px solid $accent-color;
  background-color: $black-color;
  border-radius: 50%;
  position: absolute;
  width: 2500px;
  height: 2500px;
  top: -2500px;
  right: -2500px;
  z-index: 20;
}

.spreadCircle.isActive {
  -webkit-animation: circleMoveIn 0.75s ease-in;
  -moz-animation: circleMoveIn 0.75s ease-in;
  animation: circleMoveIn 0.75s ease-in;
  transform: translate(-1500px, 1500px);
}

.spreadCircle.notActive {
  -webkit-animation: circleMoveOut 0.75s ease-out;
  -moz-animation: circleMoveOut 0.75s ease-out;
  animation: circleMoveOut 0.75s ease-in;
}

@media (min-width: 1024px) {
  .spreadCircle {
    display: none;
  }
}