// Colors
$off-white-color: #FFFFFF;
$light-gray-color: #D4D4D4;
$soft-gray-color: #B8B8B8;
$medium-gray-on-dark-bg-color: #737373;
$medium-gray-on-white-bg-color: #575757;
$dark-gray-color: #262626;
$black-color: #000000;
$accent-color: #ffdb00;
$link-color: #375E6D;
// Margins
$xs-indentation: 3.125vw;
$sm-indentation: 6.25vw;
$medium-indentation: 8.333vw;
$large-indentation: 12.5vw;
// Media queries
$xs-screen: 360px;
$sm-screen: 480px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1440px;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: $xs-indentation,
    2: $sm-indentation,
    3: $medium-indentation,
    4: $large-indentation,
  ),
  $spacers
);
// Animations
$modalAnimationDuration: .4s;
$btnAnimationDuration: 0.2s
