@import '../../../../variables.scss';

.nextCaseSection {
  height: 30vh;
  position: relative;
  overflow: hidden;
}

.link {
  width: 100%;
  height: 100%;
}

.sectionBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all .5s ease-out;
  background: center center / cover no-repeat;
  background-color: $black-color;
}

.overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black-color;
  opacity: 0.65;
  z-index: 2;
}

.contentWrapper {
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-width: 900px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nextCaseSection:hover {
  .glowText {
    text-shadow:
            0 0 5px $soft-gray-color,
            0 0 10px $medium-gray-on-dark-bg-color,
            0 0 15px $accent-color;
  }
  .sectionBackground {
    transform: scale(1.1);
  }
}

.heading {
  margin: 8px;
  transition: all .2s ease-in;
}

.description {
  color: $off-white-color;
  text-transform: initial;
}
