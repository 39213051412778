@import '../../../../variables';

.modal {
  outline: none;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 95vw;
  max-width: 800px;
  height: 80vh;
  overflow: hidden;

  @media screen and (min-width: $md-screen) {
    width: 80vw;
  }

  .formContainer {
    transition: all $modalAnimationDuration linear;
    transition-delay: $modalAnimationDuration;
    opacity: 0;
  }

  span {
    position: absolute;
    display: block;
    background: $accent-color;
    transition: all $modalAnimationDuration/2 linear;
  }

  .topBorder, .bottomBorder {
    width: 100%;
    height: 2px;
    transform: scaleX(0);
  }

  .leftBorder, .rightBorder {
    width: 2px;
    height: 100%;
    transform: scaleY(0);
  }

  .topBorder {
    top: 0;
    left: 0;
    transform-origin: top left;
  }

  .rightBorder {
    top: 0;
    right: 0;
    transform-origin: bottom left;
  }

  .bottomBorder {
    bottom: 0;
    right: 0;
    transform-origin: top left;
  }

  .leftBorder {
    top: 0;
    left: 0;
    transform-origin: bottom left;
  }

  &.isActive {

    .topBorder {
      transition-delay: $modalAnimationDuration*3/4;
    }

    .rightBorder {
      transition-delay: $modalAnimationDuration*2/4;
    }

    .bottomBorder {
      transition-delay: 0s;
    }

    .leftBorder {
      transition-delay: $modalAnimationDuration*1/4;
    }

    .topBorder,
    .bottomBorder {
      transform: scaleX(1);
    }

    .leftBorder,
    .rightBorder {
      transform: scaleY(1);
    }

   .formContainer {
     opacity: 1;
   }
  }
}

.container {
  border: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .275s ease-in;

  &.isLoaded {
    opacity: 1;
  }
}