@import "../../../../variables";

.formContainer {
  background: $off-white-color;
  width: 95vw;
  max-width: 800px;
  overflow-y: auto;
  height: 80vh;

  @media screen and (min-width: $md-screen) {
    width: 80vw;
  }
}
