@import '../../../../variables';
@import '../../../../animations';

.linkContainer {
  position: fixed;
  top: 30vh;
  left: 25vw;
  display: inline-block;
  text-align: left;
  z-index: 30;
  transform: scale(0);
}

.navLink {
  opacity: 0;
  top: .2em;
  color: $off-white-color;
  z-index: 1000;

  position: relative;
  line-height: 100%;
  font-size: 32px;
  font-weight: 500;
  margin: 20px 0;

  @media (min-width: 768px) {
    font-size: 69px;
  }
}

.isActive {
  opacity: 1;
  overflow: hidden;

  .linkContainer {
    transition-delay: 0s;
    transition-property: transform;
    transform: scale(1);
  }

  .navLink {
    animation: slideIn .6s;
  }

  .navLink:nth-child(1) {
    transition-delay: .4s;
    animation-delay: .4s;
    opacity: 1;
  }

  .navLink:nth-child(2) {
    transition-delay: .5s;
    animation-delay: .5s;
    opacity: 1;
  }

  .navLink:nth-child(3) {
    transition-delay: .6s;
    animation-delay: .6s;
    opacity: 1;
  }

  .navLink:nth-child(4) {
    transition-delay: .7s;
    animation-delay: .7s;
    opacity: 1;
  }
}

.notActive {
  .navLink {
    transition: opacity .3s ease-out;
  }

  .navLink:nth-child(1) {
    transition-delay: .3s;
  }

  .navLink:nth-child(2) {
    transition-delay: .2s;
  }

  .navLink:nth-child(3) {
    transition-delay: .1s;
  }

  .linkContainer {
    transform: scale(0);
    transition-delay: .61s;
    transition-property: transform;
  }
}

@media (min-width: 1024px) {
  .linkContainer {
    position: static;
    display: initial;
    width: unset;
    height: unset;
    opacity: 1;
  }

  .linkContainer li {
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    padding: 4px 13px;
  }

  .navLink {
    position: relative;
    margin: 0;
    animation: 0;
    opacity: 1;

    a {
      display: initial;
    }

    .active:after {
      width: 90%;
    }
  }

  .navLink a:after {
    content: '';
    background-color: $accent-color;
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 5%;
    transition: all 0.25s ease-in-out;

  }

  .navLink:hover a::after {
    width: 90%;
  }

}


