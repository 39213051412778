@import '../../../../variables';
@import '../../../../animations';

.arrowDown {
  width: 29.5px;
  height: 13.5px;
  transform: rotate(90deg);
  display: flex;
  align-self: center;
}

.arrowInner {
  background-color: $soft-gray-color;
  position: absolute;
  height: 2px;
  width: 100%;
  top: calc(50% - 1.25px);
  right: 0;
  transition: background-color .25s ease-in-out;
  animation: arrowPulse 2s infinite;
}

.arrowInner:before, .arrowInner:after {
  content: '';
  background-color: $soft-gray-color;
  display: block;
  position: absolute;
  height: 1.5px;
  width: 10.75px;
  transition: background-color .25s ease-in-out;
}

.arrowInner:before {
  top: -3.6px;
  transform: translateX(21px) rotateZ(45deg);
}

.arrowInner:after {
  top: 3.7px;
  transform: translateX(21px) rotateZ(-45deg);
}

.arrowDown:hover {
  .arrowInner, .arrowInner:before, .arrowInner:after {
    background-color: $accent-color;
  }
}

@media (max-width: 767px) {
  .arrowDown {
    display: none;
  }
}
