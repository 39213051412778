@import '../../../variables';

.navbarWrapper {
  position: fixed;
  top: 0;
  background-color: $black-color;
  height: 72px;
  width: 100vw;
  z-index: 1000;
  transition: background-color 1s ease-in;
}

.transparent{
  background-color: transparent!important;
}

.navbar {
  width: 80%;
  height: 72px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  align-items: center;

  nav {
    margin-bottom: 8px;
  }
}

.logoContainer {
  height: 40px;
  z-index: 50;

  img {
    height: 100%;
  }
}

.menuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -1px;
}

.btnWrapper {
  margin-right: 96px;
  z-index: 50;
}

@media (max-width: 767px) {
  .btnWrapper {
    display: none;
  }

}

@media (min-width: 1024px) {
  .burgerWrapper {
    display: none;
  }

  .btnWrapper {
    margin-right: 0;
  }
}