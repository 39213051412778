@import '../../../../variables.scss';
@import '../../../../animations.scss';


.gallerySection {
  align-self: center;
  padding: $large-indentation 0;
  width: 80vw;
  max-width: 1440px;
  margin: 0 auto;
}

.imageList {
  @media screen and (min-width: $md-screen) {
    display: none;
  }
}

.sliderWrapper {
  display: none;
  width: 100%;

  @media screen and (min-width: $md-screen) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.galleryImg {
  width: 100%;
  margin-bottom: 25px;
  transition: all .5s ease-out;

  @media screen and (min-width: $md-screen) {
    margin-bottom: 0;
  }
}

.imageListItem {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.iconOverlay {
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: $black-color;
  opacity: .6;
  bottom: 25px;
  right: 0;
}

.scopeIcon {
  position: absolute;
  font-size: 24px;
  color: $accent-color;
  bottom: 37px;
  right: 12px;
  animation: zoomIconPulse 2s infinite;
}

.imgWrapper {
  cursor: pointer;
  max-height: 80vh;
  position: relative;

  &:focus {
    outline: none;
  }
}

.circleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light-gray-color;
  border: 1px solid $dark-gray-color;
  cursor: pointer;
  outline: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 6px;
}

.circleButton:hover {
  background-color: $dark-gray-color;
}

.leftArrow {
  background: url('../../../../assets/leftArrow.svg') center center no-repeat;
}

.rightArrow {
  background: url('../../../../assets/rightArrow.svg') center center no-repeat;
}
