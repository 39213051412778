@import '../home.module.scss';

.aboutUsSection {
  padding-top: 68px;
  margin-top: $large-indentation;
  text-align: left;
  height: fit-content !important;

  .catchPhrase {
    margin-top: $medium-indentation;
    color: $soft-gray-color;
    display: block;
    line-height: 1.5;

    @media (min-width: 320px) {
      font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
    }
    @media (min-width: 1440px) {
      font-size: 22px;
    }
  }

  .featuresList {
    padding-left: 0;
    margin-bottom: 0;

    list-style: none;

    li::before {
      content: '·';
      margin-right: 20px;
    }
  }

  .fullPageImageContainer {
    width: 100vw;
    height: calc(90vw / 1.7778);
    margin-left: -10vw;
    overflow: hidden;
    position: relative;


    .fullPageImage {
      position: absolute;
      width: 100%;
      top: -15vw;
    }
  }
}

@media (min-width: $sm-screen) {
  body .aboutUsSection {
    .catchPhrase {
      color: $light-gray-color;
    }
  }
}

@media (min-width: $md-screen) {
  body .aboutUsSection {
    .catchPhrase {
      width: 65%;
      margin: 0 0 0 $sm-indentation;
    }

    .featuresList {
      margin: 0 auto 0 $medium-indentation;
    }
  }
}

@media (min-width: $lg-screen) {
  body .aboutUsSection {
    position: relative;
    width: 100%;
    height: calc(100vw / 1.7778);

    .contentWrapper {
      margin-left: 10vw;
      width: calc(50vw - 6.25vw);

      .catchPhrase {
        margin: 0 $xs-indentation;
        width: auto;
      }

      .featuresList {
        margin: $xs-indentation $sm-indentation;
      }
    }

    .fullPageImageContainer {
      position: absolute;
      top: 68px;
      width: 40vw;
      right: 0;
      margin-top: 0 !important;
      max-height: 110%;

      .fullPageImage {
        top: 0;
      }
    }
  }
}

@media (min-width: $xl-screen) {
  body .aboutUsSection {
    max-width: 100%;

    .catchPhrase {
      margin: 0 $medium-indentation !important;
    }

    .featuresList {
      margin: $xs-indentation $medium-indentation !important;
    }

    .fullPageImageContainer {
      margin-right: 13px;
    }
  }
}

.textLarge {
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
  }
}

.textMedium {
  display: none;
  @media screen and (min-width: 481px) and (max-width: 1023px) {
    display: block;
  }

}

.textSmall {
  display: none;
  @media screen and (max-width: $sm-screen) {
    display: block;
  }
}

.imageSkeleton {
  height: 100vh;
}

.wordWrapper {
  @media (min-width: 320px) {
    font-size: calc(2.25rem + ((1vw - 3.6px) * 5));
  }
  @media (min-width: 1440px) {
    font-size: 70px;
  }
}

.animated {
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
  }
}

.notAnimated {
  @media screen and (min-width: $lg-screen) {
    display: none;
  }
}
