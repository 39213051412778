@import '../../../../animations';

@function multiple-box-shadow ($n) {
  $value: '#{random(3000)}px #{random(3000)}px #f1b535';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(3000)}px #{random(3000)}px #f1b535';
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);
$shadows-xlarge: multiple-box-shadow(20);

.starsContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.stars, .stars2, .stars3, .stars4 {
  border-radius: 50%;
  background: transparent;

  &:after {
    content: " ";
    border-radius: 50%;
    position: absolute;
    top: 3000px;
    background: transparent;
  }
}

.stars {
  width: 1px;
  height: 1px;
  box-shadow: $shadows-small;
  -webkit-animation: animStar 50s linear infinite;
  -moz-animation: animStar 50s linear infinite;
  animation: animStar 50s linear infinite;

  &:after {
    width: 1px;
    height: 1px;
    box-shadow: $shadows-small;
  }
}

.stars2 {
  width: 2px;
  height: 2px;
  box-shadow: $shadows-medium;
  -webkit-animation: animStar 70s linear infinite;
  -moz-animation: animStar 70s linear infinite;
  animation: animStar 70s linear infinite;

  &:after {
    width: 2px;
    height: 2px;
    box-shadow: $shadows-medium;
  }
}

.stars3 {
  width: 3px;
  height: 3px;
  box-shadow: $shadows-big;
  -webkit-animation: animStar 100s linear infinite;
  -moz-animation: animStar 100s linear infinite;
  animation: animStar 100s linear infinite;

  &:after {
    width: 3px;
    height: 3px;
    box-shadow: $shadows-big;
  }
}

.stars4 {
  width: 4px;
  height: 4px;
  box-shadow: $shadows-xlarge;
  -webkit-animation: animStar 130s linear infinite;
  -moz-animation: animStar 130s linear infinite;
  animation: animStar 130s linear infinite;

  &:after {
    width: 4px;
    height: 4px;
    box-shadow: $shadows-xlarge;
  }
}


