@import '../../../../../variables';
@import '../../../../../animations';

.btn {
  display: flex;
  flex-flow: nowrap;
  color: $off-white-color;
  align-items: center;
  position: relative;
  line-height: 100%;
  cursor: pointer;
  font-family: 'Inter';
}

.dot {
  background-color: $accent-color;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 13px;
  z-index: -1;
  transition: .3s all ease-in-out;
}

.pulseAnimation {
  box-shadow: 0 0 0 0 rgba(241, 181, 53, 1);
  transform: scale(1);
  -webkit-animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

.btn:hover {
  .pulseAnimation {
    -webkit-animation: slideLeft 1s;
    -moz-animation: slideLeft 1s;
    animation: slideLeft 1s;
    transform: translate3d(-7px, 0, 0);
  }
}

