@import '../home.module.scss';

.section {
  text-align: center;
  max-width: 1440px;
  width: 80vw;
  margin: 0 10vw $large-indentation 10vw;
  box-sizing: border-box;
}

.title {
  margin-top: $xs-indentation;
  margin-bottom: $medium-indentation;
}


.annotation {
  margin-bottom: $sm-indentation;
  height: 100vw;

  @media screen and (min-width: $sm-screen) {
    height: 85vw;
  }

  @media screen and (min-width: $md-screen) {
    height: 75vw;
  }

  @media screen and (min-width: $lg-screen) {
    height: 40vw;
  }

  @media screen and (min-width: $xl-screen) {
    height: 35vw;
    max-height: 600px;
  }
}

.container > :last-child {
  margin-bottom: 0;
}

@media screen and (min-width: $lg-screen) {
  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .annotation {
    position: relative;
    width: 45%;
    margin-right: 10%;
    margin-bottom: $xs-indentation;
  }

  .annotation:nth-child(even) {
    top: 100px;
    margin-right: 0;
  }
}