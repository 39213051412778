@import "../../../../../variables";
@import "../../../../../animations";

.formHeader {
  color: $black-color;
  margin: 20px 0;
}

.contactForm {
  width: calc(100% - 80px);
  margin: 0;
  padding: 40px 40px 10px 40px;
  position: relative;

  label {
    margin-bottom: 4px;
    color: $black-color;

    span {
      background: transparent;
      display: inline;
      position: initial;
      color: $black-color;
    }

    .mandatory {
      color: red !important;
    }
  }

}

.inputBlock {
  margin-bottom: 18px;
}

.formInput {
  box-sizing: border-box;
  padding: 5px 15px;
  min-height: 40px;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid $light-gray-color;
  border-radius: 0;
  background-color: white;

  &:focus {
    outline: none;
    border-color: $accent-color;
  }

}

textarea.formInput {
  padding: 15px;
}

.formText {
  color: $black-color !important;
  font-size: small!important;
}

.checkboxBlock {
  margin-bottom: 4px;
  position: relative;
}

.checkboxLabel {
  cursor: pointer;
}

.formCheckbox {
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 5px 3px 0;
  vertical-align: middle;
}

.actions {
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 17px 0;
}

.submit {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  position: relative;
  text-align: center;
  background-color: $accent-color;
  border: 2px solid $accent-color;
  color: $off-white-color;
  font-size: 14px;
  padding: 12px 24px;
  border-radius: 0;
  transition: 0.3s all;
  width: 100px;
  height: 48px;

  &:hover {
    color: $dark-gray-color;
    background-color: $off-white-color;
  }

  &:hover > div {
    background-color: $dark-gray-color;
  }

  &:focus {
    outline: none;
  }
}

.requiredWrapper {
  div {
    color: #f2545b;
  }
}

.required {
  input {
    border-color: #f2545b !important;
  }

  div {
    display: block!important;
  }
}

.spinner {
  width: 48px;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: $off-white-color;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.closeBtn {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 48px;
  width: 48px;
  color: $medium-gray-on-dark-bg-color;
}

.closeBtn:hover, .closeBtn:active {
  color: $black-color;
}

.closeIcon {
  font-size: 24px;
}
