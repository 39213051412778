@import '../../../../../variables';
@import '../../../../../animations';

.ctaContainer {
  width: 60vw;
  margin: calc(30vh - 10vw + 64px) auto 10vh auto;
  display: flex;
  flex-direction: column;

  span {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: $off-white-color;
    align-self: flex-start;
    padding: .2em 0;

    &.cta {
      align-self: flex-end;
    }
  }
}

//========= animated heading styles ========
.cdWordsWrapper {
  position: relative;
  overflow: hidden;

  b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    transition: 0.3s all;
    font-weight: 300;
    opacity: 0;
    top: .2em;
  }

  b.isVisible {
    position: relative;
    top: 0.125em;
    opacity: 1;
    -webkit-animation: slideIn 0.6s;
    -moz-animation: slideIn 0.6s;
    animation: slideIn 0.6s;
  }

  b.isHidden {
    -webkit-animation: slideOut 0.6s;
    -moz-animation: slideOut 0.6s;
    animation: slideOut 0.6s;
  }

  .marker {
    -webkit-animation: dotToLine 0.3s;
    -moz-animation: dotToLine 0.3s;
    animation: dotToLine 0.3s;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $accent-color;
    width: 100%;
    min-height: 3px;
    z-index: -1;

  }
}

.cdWordsWrapper:hover {
  cursor: pointer;

  b {
    padding-right: 1rem;
  }

  .marker {
    -webkit-animation: lineToDot 0.3s;
    -moz-animation: lineToDot 0.3s;
    animation: lineToDot 0.3s;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 20%;
  }
}

@media (min-width: 1440px) {

  .cdWordsWrapper {
    align-self: flex-start !important;
    margin-left: 30vw;
  }
}


