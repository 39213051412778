@-webkit-keyframes animStar {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-2000px)
  }
}

@-moz-keyframes animStar {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-2000px)
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-2000px)
  }
}

//============= text animation ===============
@-webkit-keyframes slideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideOut {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  40% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}

@-moz-keyframes slideOut {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  40% {
    opacity: 0;
    -moz-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -moz-transform: translateY(120%);
    -ms-transform: translateY(120%);
    -o-transform: translateY(120%);
    transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}


// ========= underline animation ============
@-webkit-keyframes lineToDot {
  0% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
  25% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  85% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  100% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 20%;
  }
}

@-moz-keyframes lineToDot {
  0% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
  25% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  85% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  100% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 20%;
  }
}

@keyframes lineToDot {
  0% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
  25% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  85% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  100% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 20%;
  }
}

@-webkit-keyframes dotToLine {
  0% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 1.15vw;
  }
  15% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  25% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  100% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
}

@-moz-keyframes dotToLine {
  0% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 1.15vw;
  }
  15% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  25% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  100% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
}

@keyframes dotToLine {
  0% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 1.15vw;
  }
  15% {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
  }
  25% {
    width: 25%;
    height: 5px;
    border-radius: 20%;
    bottom: 0;
  }
  50% {
    width: 50%;
    height: 3px;
    border-radius: 15%;
    bottom: 0;
  }
  75% {
    width: 75%;
    height: 3px;
    border-radius: 5%;
    bottom: 0;
  }
  100% {
    width: 100%;
    height: 3px;
    border-radius: 0%;
    bottom: 0;
  }
}

//==================== top menu spread circle ==============
@keyframes circleMoveIn {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-1500px, 1500px);
  }
}

@-webkit-keyframes circleMoveIn {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-1500px, 1500px);
  }
}

@-moz-keyframes circleMoveIn {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-1500px, 1500px);
  }
}

@keyframes circleMoveOut {
  0% {
    transform: translate(-1500px, 1500px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes circleMoveOut {
  0% {
    transform: translate(-1500px, 1500px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@-moz-keyframes circleMoveOut {
  0% {
    transform: translate(-1500px, 1500px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(241, 181, 53, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0);
  }
}

@-moz-keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(241, 181, 53, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(241, 181, 53, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(241, 181, 53, 0);
  }
}

@-webkit-keyframes arrowPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@-moz-keyframes arrowPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes arrowPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

//============ pulse dot touches text on GET IN TOUCH btn ================
@-webkit-keyframes slideLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  10% {
    transform: translate3d(2px, 0, 0);
  }

  60% {
    transform: translate3d(-12px, 0, 0);
  }

  90% {
    transform: translate3d(-6px, 0, 0);
  }
  100% {
    transform: translate3d(-7px, 0, 0);
  }
}

@-moz-keyframes slideLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  10% {
    transform: translate3d(2px, 0, 0);
  }

  60% {
    transform: translate3d(-12px, 0, 0);
  }

  90% {
    transform: translate3d(-6px, 0, 0);
  }
  100% {
    transform: translate3d(-7px, 0, 0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  10% {
    transform: translate3d(2px, 0, 0);
  }

  60% {
    transform: translate3d(-12px, 0, 0);
  }

  90% {
    transform: translate3d(-6px, 0, 0);
  }
  100% {
    transform: translate3d(-7px, 0, 0);
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@keyframes zoomIconPulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes zoomIconPulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

@-moz-keyframes zoomIconPulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}