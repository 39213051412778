@import '../../../index.scss';

.casePage {

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  .container {
    max-width: 1440px;
    width: 80vw;
    height: 100%;
    box-sizing: border-box;

  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $large-indentation 0;

    @media screen and (min-width: $lg-screen) {
      flex-direction: row;
      justify-content: space-between;

    }
  }

  .topLine {
    border-top: 1px solid $dark-gray-color;
  }

  .title {
    flex: 2;
    text-align: left;
    width: 100%;
  }

  .titleContainer {
    padding-bottom: $medium-indentation;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $sm-screen) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (min-width: $lg-screen) {
      flex-direction: column;
    }
  }

  .titleItem {
    margin-bottom: $medium-indentation;
    flex: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    span {
      color: $black-color;
    }

    @media screen and (min-width: $sm-screen) {
      margin-bottom: $xs-indentation;
    }

    b {
      padding-bottom: 10px;
      font-size: 15px;
    }

    span {
      @media (min-width: 320px) {
        font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
      }
      @media (min-width: 1440px) {
        font-size: 22px;
      }
    }
  }

  .content {
    flex: 3;
    text-align: left;

    p {
      margin-top: 0;
    }
  }

  .heading {
    display: inline-block;
    margin-top: 0;
  }

  .content{
    ol {
      counter-reset: my-awesome-counter;
      margin-top: $xs-indentation;
      @media screen and (min-width: $lg-screen) {
        margin-top: $xs-indentation;
      }
    }

    ul {
      margin-top: $xs-indentation;
      @media screen and (min-width: $lg-screen) {
        margin-top: $xs-indentation;
      }
      padding-left: 40px;
    }

    li {
      margin-bottom: $sm-indentation;
      padding-left: 10px;
      position: relative;
      @media screen and (min-width: $lg-screen) {
        margin-bottom: $xs-indentation;
      }
      @media (min-width: 320px) {
        font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
      }
      @media (min-width: 1440px) {
        font-size: 22px;
      }
    }

    ol li {
      counter-increment: my-awesome-counter;
    }

    li::before {
      color: $accent-color;
      position: absolute;
      --size: 36px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: calc(50% - var(--size) / 2);
      background: transparent;
      border: 1px solid #262626;
      border-radius: 50%;
      text-align: center;
    }

    ol li::before {
      content: counter(my-awesome-counter);
    }

    ul li::before {
      content: '\2713';
    }
  }


}
