@import '../../../../variables.scss';

.caseSection {
  height: 100vh;
  position: relative;
  background: center center / cover no-repeat;
  background-color: $black-color;
}

.overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black-color;
  opacity: 0.65;
  z-index: 2;
}
.container  {
  max-width: 1440px;
  width: 80vw;
  height: 100%;
  box-sizing: border-box;
}

.contentWrapper {
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-width: 900px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.heading {
  text-align: left;
}

.arrow {
  position: absolute;
  bottom: 10vh;
  left: 0;
}