@import '../../home.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

body .name {
  color: $off-white-color;
  text-align: left;
}

.position {
  color: $soft-gray-color;
}
