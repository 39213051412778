@import '../../../../variables.scss';

.subtitle {
  display: block;
  color: $soft-gray-color;
}

.container {
  text-align: left;
  cursor: pointer;
}

.image {
  transition: all .5s ease-out;
  width: 100%;
}

.imgContainer {
  height: 50vw;
  overflow: hidden;

  @media screen and (min-width: $md-screen) {
    &.small {
      height: 22vw;
    }
  }

  @media screen and (min-width: $lg-screen) {
    height: 22vw;
  }

  @media screen and (min-width: $xl-screen) {
    max-width: 640px;
    max-height: 400px;
  }

  margin-bottom: $xs-indentation;
}

.btn {
  display: flex;
  flex-flow: nowrap;
  color: $off-white-color;
  align-items: center;
  position: relative;
  line-height: 100%;
}

.dot {
  background-color: $accent-color;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 13px;
  z-index: -1;
  transition: .3s all ease-in-out;
}

.arrow {
  color: $black-color;
  position: relative;
  margin-left: -10px;
  opacity: 0;
  transition: .3s all ease-in-out;
}

.container:hover {
  .arrowAnimation {
    transform: scale(8, 8);
  }

  .arrowAnimation ~ .arrow {
    opacity: 1;
  }

  .image {
    transform: scale(1.1);
  }
}

.overflowEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
