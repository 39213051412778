@import '../home.module.scss';

.section {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $md-screen) {
    flex-wrap: wrap;
    flex-direction: row;
  }

}

.title {
  margin-top: $xs-indentation;
  margin-bottom: $medium-indentation;

  @media screen and (min-width: $lg-screen) {
    width: 90%;
  }

  @media screen and (max-width: $sm-screen) {
    width: 80%;
  }
}

.annotation {
  margin-bottom: $sm-indentation;
  height: 100vw;

  @media screen and (min-width: $sm-screen) {
    height: 85vw;
  }

  @media screen and (min-width: $md-screen) {
    height: 50vw;
  }

  @media screen and (min-width: $lg-screen) {
    height: 40vw;
  }

  @media screen and (min-width: $xl-screen) {
    height: 35vw;
    max-height: 550px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 45%;

  //@media screen and (max-width: $md-screen) {
  @media screen and (max-width: 767px) {
    width: 100%;

    &:nth-child(1) {
      order: 2;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
