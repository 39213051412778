@import '../../home.module.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;


  @media screen and (max-width: $sm-screen) {
    margin-right: 0;
    margin-left: 0;
  }
}

body .text {
  margin: 0 0 $xs-indentation 0;
  color: $off-white-color;
  text-transform: initial!important;
  text-align: left;

  span {
    @media (min-width: 320px) {
      font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
    }
    @media (min-width: 1440px) {
      font-size: 22px;
    }
  }
}

.card {
  border: 1px solid $dark-gray-color;
  max-width: 460px;
  padding: $xs-indentation;
  margin-left: $xs-indentation;
  margin-right: $xs-indentation;
}

