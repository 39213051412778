@import '../../../../variables';

.section {
  color: $off-white-color;
  height: 100vh;
}

.paragraphWrapper {
  color: $light-gray-color;
  text-align: left;

  p {
    margin: 0;
  }

  @media (min-width: 1440px) {
    margin-left: 15vw;
  }
}

.wave {
  position: absolute;
  //top: calc(50vh - 13vw);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.lookForUsWrapper {
  position: absolute;
  bottom: 10%;
  width: 80%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $md-screen) {
    justify-content: space-between;
  }

}

.lookForUsWording {
  color: $soft-gray-color;
  display: none;

  @media screen and (min-width: $lg-screen) {
    display: flex;
  }
}
