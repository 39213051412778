@import '../home.module.scss';

.section {
  text-align: left;
}

.icon {
  fill: $light-gray-color;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -6px;
  order: 1;

  @media screen and (min-width: $sm-screen) {
    order: 0;
    align-items: flex-end;
    margin-left: $xs-indentation;
    margin-bottom: $medium-indentation;
  }
}

.reviewsContainer {
  flex: 1;
  width: 100%;
  margin-left: -$xs-indentation;
  margin-right: calc(-10vw + 8px);
  margin-bottom: $xs-indentation;

  @media screen and (max-width: $sm-screen) {
    margin-right: 0;
    margin-left: 0;
  }

  @media screen and (min-width: $xl-screen) {
    width: 80vw;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $sm-screen) {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.titleContainer {
  flex: 1;
  margin-top: $xs-indentation;
  margin-bottom: $medium-indentation;
}

.title {
  margin: 0;
  width: 90%;

  @media screen and (min-width: $lg-screen) {
    width: 75%
  }

  @media screen and (min-width: $xl-screen) {
    width: 70%
  }
}

.circleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light-gray-color;
  border: 1px solid $dark-gray-color;
  cursor: pointer;
  outline: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 6px;
}

.circleButton:hover {
  background-color: $dark-gray-color;
}

.leftArrow {
  background: url('../../../../assets/leftArrow.svg') center center no-repeat;
}

.rightArrow {
  background: url('../../../../assets/rightArrow.svg') center center no-repeat;
}

.heightHolder {
  opacity: 0;
}


