@import '../../../../variables';

article {
  overflow-x: hidden;
}

.articleContainer {
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 100px;
}

//================ skeleton ===============
.skeletonContainer {
  min-height: 100vh;
}

.skeletonHeading {
  display: block;
  margin: $xs-indentation auto;

  span {
    height: 26px;
    margin-bottom: 4px;
  }
}

.skeletonSubheading {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 50%;

  span {
    height: 20px;
  }
}

.skeletonTags {
  margin-top: calc(5px - 3.125vw);
  width: 80%;
}

.skeletonImg {
  min-width: 40%;
  max-width: 100%;
  margin: $xs-indentation auto;

  span {
    height: 20vh;
  }
}

.skeletonParagraph {
  margin: 17px 0;

  span {
    height: 18px;
  }
}


@media (min-width: $md-screen) {
  .skeletonHeading {
    span {
      height: 40px;
    }
  }

  .skeletonSubheading {
    span {
      height: 24px;
    }
  }

  .skeletonParagraph {
    span {
      height: 21px;
    }
  }

}
