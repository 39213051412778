@import "../../../variables";

.legalSection {
  padding-top: $sm-indentation;
  padding-bottom: $xs-indentation;
}

.container {
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  width: 90vw;
  flex-flow: column-reverse;


  @media screen and (min-width: $md-screen) {
    width: 80vw;
    flex-flow: row;
    justify-content: space-around;
  }

}

.navContainer {
  flex: 2;
  margin-right: 30px;
  margin-top: 20px;
  text-align: left;
  width: 100%;

  a {
    display: block;
    padding: 5px;
    margin: 20px 0;
    //background-color: $light-gray-color;
    //border-radius: 5px;
    border-bottom: 1px solid $black-color;
    font-size: small;
    color: $black-color;
  }

  a:hover {
    color: $accent-color;
    border-bottom:1px solid $accent-color;
  }
}

.articleContainer {
  flex: 5;
  text-align: left;
  margin: 0 auto;
  max-width: 800px;
}