@import "../../../variables";

.notFoundPage {
  height: calc(100vh - 64px);
}

.messageWrapper {
  margin: 20vh auto;
  padding: 0 20vw;
}

.notFoundMessage {
  font-weight: 700;
}