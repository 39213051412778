@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables';
@import './animations';


html {
}

body {

  h1, h2, h3, h4, h5 {
    font-weight: 300;
  }

  h1 {
    line-height: 1.067;
  }

  h2 {
    line-height: 1.1;
  }

  h3 {
    line-height: 1.125;
  }

  h4, h5 {
    line-height: 1.2;
  }

  p {
    line-height: 1.5;
  }

  .small {
    line-height: 1.7;
    color: $accent-color;
  }

  span {
    line-height: 20px;
    color: $accent-color;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
    color: $off-white-color;

    :hover {
      text-decoration: none;
    }

    :active {
      color: $off-white-color;
    }

    :visited {
      color: $off-white-color;
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    font-size: 13px;
    height: 20px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    outline: none;
    white-space: nowrap;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  img {
    border: none;
    box-shadow: none;
    text-decoration: none;
  }

  /* 32px @ 320px increasing to 90px @ 1440px */
  @media (min-width: 320px) {
    h1 {
      font-size: calc(2.25rem + ((1vw - 3.6px) * 5));
      /* Where: * 5 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    h2 {
      font-size: calc(2rem + ((1vw - 3.6px) * 2.963));
    }
    h3 {
      font-size: calc(1.75rem + ((1vw - 3.6px) * 1.5741));
    }
    h4 {
      font-size: calc(1.5625rem + ((1vw - 3.6px) * 0.6481));
    }
    h5 {
      font-size: calc(1.3125rem + ((1vw - 3.6px) * 0.5556));
    }
    p {
      font-size: calc(1.125rem + ((1vw - 3.6px) * 0.3704));
    }
    .small {
      font-size: calc(1rem + ((1vw - 3.6px) * 0.1852));
    }
  }

  /* Prevent font scaling beyond this breakpoint */
  @media (min-width: 1440px) {
    h1 {
      font-size: 90px;
    }
    h2 {
      font-size: 64px;
    }
    h3 {
      font-size: 45px;
    }
    h4 {
      font-size: 32px;
    }
    h5 {
      font-size: 27px;
    }
    p {
      font-size: 22px;
    }
    .small {
      font-size: 18px;
    }
    span {
      font-size: 15px;
      font-weight: 500;
    }
  }
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $black-color;
  color: $off-white-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b#{$size},
    .#{$abbrev}y#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l#{$size},
    .#{$abbrev}x#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

.reviews {
  .slick-list {
    display: flex !important;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    display: flex !important;

    & > div {
      display: flex !important;
    }
  }
}

.gallery {
  width: 60vw;

  .slick-dots {
    li {
      button::before {
        color: $soft-gray-color;
        opacity: 1;
      }
    }

    li.slick-active {
      button::before {
        opacity: 1;
        color: $accent-color;
      }
    }
  }
}

.white-background {
  background: $off-white-color;
  color: $black-color;

  a {
    color: $link-color;
  }
}

article {
  background-color: white !important;
  color: #333;
  padding: 64px $medium-indentation;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;

  h1 {
    font-size: 26px;
    display: block;
    margin: $xs-indentation auto;
    text-align: left;
    font-weight: 600;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 1.3;
    margin: 17px 0;
  }

  ul {
    margin-left: $xs-indentation;
  }

  li {
    font-size: 18px;
    line-height: 28px;
    padding: 0.2em 0;
  }

  @media (min-width: $md-screen) {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 21px;
    }
    ul {
      margin-left: $xs-indentation;
    }
    li {
      font-size: 21px;
      line-height: 28px;
      padding: 0.5em 0;
    }
  }

  ul {
    margin-left: $xs-indentation;
  }

  li {
    list-style: initial;
  }

  img {
    min-width: 40%;
    max-width: 100%;
    display: block;
    margin: $xs-indentation auto;
  }

  pre {
    background-color: $off-white-color;
    color: $dark-gray-color;
    padding: 5px $xs-indentation;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    white-space: pre-wrap;
  }

  a {
    color: rgb(5, 82, 181);
    text-transform: initial;
  }

}

.grecaptcha-badge {
  visibility: hidden;
}
