
.init {
  transition: all .5s cubic-bezier(.52,.91,.93,1.03);
  opacity: 0;
}

.bottom {
  transform: translateY(60px);
}

.right {
  transform: translateX(-60px);
}

.left {
  transform: translateX(60px);
}

.resetY {
  opacity: 1;
  transform: translateY(0);
}

.resetX {
  opacity: 1;
  transform: translateX(0);
}