@import '../../../../../variables';

.wrapper {
  display: flex;
  align-items: center;

  :nth-child(2) {
    order: 3;
  }

  :last-child {
    order: 4;
  }
}

.image {
  height: 100%;
}

.link {
  line-height: 100%;
  margin-right: 18px;
  height: 12px;

  @media screen and (min-width: $sm-screen) {
    margin-right: 40px;
    height: 14px;
  }

  @media screen and (min-width: $md-screen) {
    height: 16px;
  }

  @media screen and (min-width: $lg-screen) {
    margin-right: 60px;
    height: 100%;
  }
}

.link:hover {
  filter: brightness(3) contrast(2);
}

.lookForUsWording {
  color: $soft-gray-color;
  display: none;

  @media screen and (min-width: $lg-screen) {
    display: block;
    margin-right: $medium-indentation;
  }
}